<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">题库管理</a>
          <i>></i>
          <a href="javascript:;" @click="toQuestionBankManage">题库管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">题目管理</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 20px;line-height: 32px;">
            <div title="分类名称" class="searchboxItem ci-full">
              <span class="itemLabel">题库名称:</span>
              <span style="text-align: left">{{ bankName }}</span>
            </div>
          </div>
          <div class="searchbox">
            <div title="题目内容" class="searchboxItem ci-full">
              <span class="itemLabel">题目内容:</span>
              <el-input v-model="questionName" type="text" size="small" clearable placeholder="请输入题目内容" />
            </div>
            <div title="题目类型" class="searchboxItem ci-full">
              <span class="itemLabel">题目类型:</span>
              <el-select v-model="questionType" placeholder="请选择题目类型" size="small" clearable>
                <el-option v-for="item in QuestionTypeList" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
            <div title="知识点" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 8em;">知识点:</span>
              <el-cascader v-model="knowledgeId" :options="knowledgeIdList" :props="{
                checkStrictly: true,
                emitPath: false,
                label: 'knowledgeName',
                value: 'knowledgeId',
                children: 'children'
              }" filterable clearable placeholder="请选择知识点" size="small" style="width: 400px;">
              </el-cascader>
            </div>
          </div>
          <div class="searchbox" style="margin-bottom: 5px;margin-top: 20px;">
            <div title="学员是否可见" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 8em;">学员是否可见:</span>
              <el-select v-model="userShow" placeholder="请选择学员是否可见" size="small" clearable>
                <el-option key="" label="全部" value=""></el-option>
                <el-option :key="true" label="是" :value="true"></el-option>
                <el-option :key="false" label="否" :value="false"></el-option>
              </el-select>
            </div>
            <el-button style="margin-left: 20px" class="bgc-bv" round @click="getData()">查询</el-button>
            <el-button class="bgc-bv" round @click="handleCreate('')">新增</el-button>
            <el-button class="bgc-bv" round @click="handleExport()">基础题导入</el-button>
            <el-button class="bgc-bv" round @click="handleExportToUnite()">组合题导入</el-button>
            <!--            <span style="position:absolute;bottom: calc(0.675rem + 5px);right: 0">-->
            <!--              -->
            <!--            </span>-->


          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <div class="questionBox">
              <div v-for="(item, index) in tableData" :key="index">
                <!--单选题-->
                <div class="questionItem" v-if="item.questionType == '10'">
                  <div class="questionInner">
                    <div class="questionInnerLeft">
                      <div class="questionTitle">
                        <span class="questionType">单选题</span><span>{{ indexMethod(index) }}.</span><span
                          v-html="item.questionName" class="hh"></span>
                      </div>
                      <div class="questionOption">
                        <span v-for="(itemInner, indexInner) in JSON.parse(item.optionJson)" :key="indexInner">
                          {{ itemInner.key }}.{{ itemInner.content }}
                          <p v-if="itemInner.img">
                            <el-image style="height: 100px;min-height: 100px;" :src="itemInner.img" :fit="cover"
                              :preview-src-list="[itemInner.img]">
                            </el-image>
                          </p>

                        </span>
                      </div>
                      <div class="questionAnswer">
                        <span>
                          正确答案：{{ item.standardAnswer }}
                        </span>
                      </div>
                      <div class="questionAnalysis">
                        <span>
                          解析：<span v-html="item.answerAnalysis"></span>
                        </span>
                      </div>
                      <div class="knowledge">
                        <span>
                          知识点：<span>{{ item.knowledgeNamePath }}</span>
                        </span>
                      </div>
                    </div>
                    <div class="questionInnerRight">
                      <el-button type="warning" size="mini" icon="el-icon-edit"
                        @click="handleCreate(item.questionId)">编辑</el-button>
                      <el-button type="danger" size="mini" icon="el-icon-delete"
                        @click="handleDel(item.questionId)">删除</el-button>
                    </div>
                  </div>
                </div>
                <!--多选题-->
                <div class="questionItem" v-if="item.questionType == '20'">
                  <div class="questionInner">
                    <div class="questionInnerLeft">
                      <div class="questionTitle">
                        <span class="questionType">多选题</span><span>{{ indexMethod(index) }}.</span><span
                          v-html="item.questionName" class="hh"></span>
                      </div>
                      <div class="questionOption">
                        <span v-for="(itemInner, indexInner) in JSON.parse(item.optionJson)" :key="indexInner">
                          {{ itemInner.key }}.{{ itemInner.content }}
                          <p v-if="itemInner.img">
                            <el-image style="height: 100px;min-height: 100px;" :src="itemInner.img" :fit="cover"
                              :preview-src-list="[itemInner.img]">
                            </el-image>
                          </p>

                        </span>
                      </div>
                      <div class="questionAnswer">
                        <span>
                          正确答案：{{ item.standardAnswer }}
                        </span>
                      </div>
                      <div class="questionAnalysis">
                        <span>
                          解析：<span v-html="item.answerAnalysis"></span>
                        </span>
                      </div>
                      <div class="knowledge">
                        <span>
                          知识点：<span>{{ item.knowledgeNamePath }}</span>
                        </span>
                      </div>
                    </div>
                    <div class="questionInnerRight">
                      <el-button type="warning" size="mini" icon="el-icon-edit"
                        @click="handleCreate(item.questionId)">编辑</el-button>
                      <el-button type="danger" size="mini" icon="el-icon-delete"
                        @click="handleDel(item.questionId)">删除</el-button>
                    </div>
                  </div>
                </div>
                <!--填空题-->
                <div class="questionItem" v-if="item.questionType == '40'">
                  <div class="questionInner">
                    <div class="questionInnerLeft">
                      <div class="questionTitle">
                        <span class="questionType">填空题</span><span>{{ indexMethod(index) }}.</span><span
                          v-html="item.questionName" class="hh"></span>
                      </div>
                      <div class="questionAnswer">
                        <span>
                          参考答案：
                          <p class="questionAnswerS" v-for="(itemInner, indexInner) in item.standardAnswer.split('####')"
                            :key="indexInner">{{ itemInner }}</p>
                        </span>
                      </div>
                      <div class="questionAnalysis">
                        <span>
                          解析：<span v-html="item.answerAnalysis"></span>
                        </span>
                      </div>
                      <div class="knowledge">
                        <span>
                          知识点：<span>{{ item.knowledgeNamePath }}</span>
                        </span>
                      </div>
                    </div>
                    <div class="questionInnerRight">
                      <el-button type="warning" size="mini" icon="el-icon-edit"
                        @click="handleCreate(item.questionId)">编辑</el-button>
                      <el-button type="danger" size="mini" icon="el-icon-delete"
                        @click="handleDel(item.questionId)">删除</el-button>
                    </div>
                  </div>
                </div>
                <!--判断题-->
                <div class="questionItem" v-if="item.questionType == '30'">
                  <div class="questionInner">
                    <div class="questionInnerLeft">
                      <div class="questionTitle">
                        <span class="questionType">判断题</span><span>{{ indexMethod(index) }}.</span><span
                          v-html="item.questionName" class="hh"></span>
                      </div>
                      <div class="questionOption">
                        <span v-for="(itemInner, indexInner) in JSON.parse(item.optionJson)" :key="indexInner">
                          {{ itemInner.key }}.{{ itemInner.content }}
                          <p v-if="itemInner.img">
                            <el-image style="height: 100px;min-height: 100px;" :src="itemInner.img" :fit="cover"
                              :preview-src-list="[itemInner.img]">
                            </el-image>
                          </p>

                        </span>
                      </div>
                      <div class="questionAnswer">
                        <span>
                          正确答案：{{ item.standardAnswer }}
                        </span>
                      </div>
                      <div class="questionAnalysis">
                        <span>
                          解析：<span v-html="item.answerAnalysis"></span>
                        </span>
                      </div>
                      <div class="knowledge">
                        <span>
                          知识点：<span>{{ item.knowledgeNamePath }}</span>
                        </span>
                      </div>
                    </div>
                    <div class="questionInnerRight">
                      <el-button type="warning" size="mini" icon="el-icon-edit"
                        @click="handleCreate(item.questionId)">编辑</el-button>
                      <el-button type="danger" size="mini" icon="el-icon-delete"
                        @click="handleDel(item.questionId)">删除</el-button>
                    </div>
                  </div>
                </div>
                <!--简答题-->
                <div class="questionItem" v-if="item.questionType == '50'">
                  <div class="questionInner">
                    <div class="questionInnerLeft">
                      <div class="questionTitle">
                        <span class="questionType">简答题</span><span>{{ indexMethod(index) }}.</span><span
                          v-html="item.questionName" class="hh"></span>
                      </div>
                      <div class="questionAnswer">
                        <span>
                          参考答案：{{ item.standardAnswer }}
                        </span>
                      </div>
                      <div class="questionAnalysis">
                        <span>
                          解析：<span v-html="item.answerAnalysis"></span>
                        </span>
                      </div>
                      <div class="knowledge">
                        <span>
                          知识点：<span>{{ item.knowledgeNamePath }}</span>
                        </span>
                      </div>
                    </div>
                    <div class="questionInnerRight">
                      <el-button type="warning" size="mini" icon="el-icon-edit"
                        @click="handleCreate(item.questionId)">编辑</el-button>
                      <el-button type="danger" size="mini" icon="el-icon-delete"
                        @click="handleDel(item.questionId)">删除</el-button>
                    </div>
                  </div>
                </div>
                <!--计算题-->
                <div class="questionItem" v-if="item.questionType == '60'">
                  <div class="questionInner">
                    <div class="questionInnerLeft">
                      <div class="questionTitle">
                        <span class="questionType">计算题</span><span>{{ indexMethod(index) }}.</span><span
                          v-html="item.questionName" class="hh"></span>
                      </div>
                      <div class="questionAnswer">
                        <span>
                          参考答案：{{ item.standardAnswer }}
                        </span>
                      </div>
                      <div class="questionAnalysis">
                        <span>
                          解析：<span v-html="item.answerAnalysis"></span>
                        </span>
                      </div>
                      <div class="knowledge">
                        <span>
                          知识点：<span>{{ item.knowledgeNamePath }}</span>
                        </span>
                      </div>
                    </div>
                    <div class="questionInnerRight">
                      <el-button type="warning" size="mini" icon="el-icon-edit"
                        @click="handleCreate(item.questionId)">编辑</el-button>
                      <el-button type="danger" size="mini" icon="el-icon-delete"
                        @click="handleDel(item.questionId)">删除</el-button>
                    </div>
                  </div>
                </div>

                <!--论述题-->
                <div class="questionItem" v-if="item.questionType == '64'">
                  <div class="questionInner">
                    <div class="questionInnerLeft">
                      <div class="questionTitle">
                        <span class="questionType">论述题</span><span>{{ indexMethod(index) }}.</span><span
                          v-html="item.questionName" class="hh"></span>
                      </div>
                      <div class="questionAnswer">
                        <span>
                          参考答案：{{ item.standardAnswer }}
                        </span>
                      </div>
                      <div class="questionAnalysis">
                        <span>
                          解析：<span v-html="item.answerAnalysis"></span>
                        </span>
                      </div>
                      <div class="knowledge">
                        <span>
                          知识点：<span>{{ item.knowledgeNamePath }}</span>
                        </span>
                      </div>
                    </div>
                    <div class="questionInnerRight">
                      <el-button type="warning" size="mini" icon="el-icon-edit"
                        @click="handleCreate(item.questionId)">编辑</el-button>
                      <el-button type="danger" size="mini" icon="el-icon-delete"
                        @click="handleDel(item.questionId)">删除</el-button>
                    </div>
                  </div>
                </div>

                <!--案例分析题-->
                <div class="questionItem" v-if="item.questionType == '68'">
                  <div class="questionInner">
                    <div class="questionInnerLeft">
                      <div class="questionTitle">
                        <span class="questionType">案例分析题</span><span>{{ indexMethod(index) }}.</span><span
                          v-html="item.questionName" class="hh"></span>
                      </div>
                      <div class="questionAnswer">
                        <span>
                          参考答案：{{ item.standardAnswer }}
                        </span>
                      </div>
                      <div class="questionAnalysis">
                        <span>
                          解析：<span v-html="item.answerAnalysis"></span>
                        </span>
                      </div>
                      <div class="knowledge">
                        <span>
                          知识点：<span>{{ item.knowledgeNamePath }}</span>
                        </span>
                      </div>
                    </div>
                    <div class="questionInnerRight">
                      <el-button type="warning" size="mini" icon="el-icon-edit"
                        @click="handleCreate(item.questionId)">编辑</el-button>
                      <el-button type="danger" size="mini" icon="el-icon-delete"
                        @click="handleDel(item.questionId)">删除</el-button>
                    </div>
                  </div>
                </div>

                <!--组合题(单一题型)-->
                <div class="questionItem" v-if="item.questionType == '80'">
                  <div class="questionInner">
                    <div class="questionInnerLeft">
                      <div class="questionTitle">
                        <span class="questionType">组合题(单一题型)</span><span>{{ indexMethod(index) }}.</span><span
                          v-html="item.questionName" class="hh"></span>
                      </div>
                      <span class="showChildrenQuestion"
                        @click="showChildrenQuestion(index)">点击{{ item.showChild ? '隐藏' : '查看' }}子题目</span>
                      <!--子题目-->
                      <div class="childrenQuestion" v-if="item.showChild">

                        <div v-for="(itemInner, indexInner) in item.childList" :key="indexInner">
                          <!--子题目~单选-->
                          <div class="childrenQuestionItem" v-if="itemInner.questionType == '10'">
                            <div class="questionTitle">
                              <span class="questionType">单选题</span><span>{{ indexInner + 1 }}.</span><span
                                v-html="itemInner.questionName" class="hh"></span>
                            </div>
                            <div class="questionOption">
                              <span v-for="(itemInner2, indexInner2) in JSON.parse(itemInner.optionJson)"
                                :key="indexInner2">
                                {{ itemInner2.key }}.{{ itemInner2.content }}
                                <p v-if="itemInner2.img">
                                  <el-image style="height: 100px;min-height: 100px;" :src="itemInner2.img" :fit="cover"
                                    :preview-src-list="[itemInner2.img]">
                                  </el-image>
                                </p>

                              </span>
                            </div>
                            <div class="questionAnswer">
                              <span>
                                正确答案：{{ itemInner.standardAnswer }}
                              </span>
                            </div>
                            <div class="questionAnalysis">
                              <span>
                                解析：<span v-html="itemInner.answerAnalysis"></span>
                              </span>
                            </div>
                            <div class="knowledge">
                              <span>
                                知识点：<span>{{ itemInner.knowledgeNamePath }}</span>
                              </span>
                            </div>
                          </div>
                          <!--子题目~多选-->
                          <div class="childrenQuestionItem" v-if="itemInner.questionType == '20'">
                            <div class="questionTitle">
                              <span class="questionType">多选题</span><span>{{ indexInner + 1 }}.</span><span
                                v-html="itemInner.questionName" class="hh"></span>
                            </div>
                            <div class="questionOption">
                              <span v-for="(itemInner2, indexInner2) in JSON.parse(itemInner.optionJson)"
                                :key="indexInner2">
                                {{ itemInner2.key }}.{{ itemInner2.content }}
                                <p v-if="itemInner2.img">
                                  <el-image style="height: 100px;min-height: 100px;" :src="itemInner2.img" :fit="cover"
                                    :preview-src-list="[itemInner2.img]">
                                  </el-image>
                                </p>

                              </span>
                            </div>
                            <div class="questionAnswer">
                              <span>
                                正确答案：{{ itemInner.standardAnswer }}
                              </span>
                            </div>
                            <div class="questionAnalysis">
                              <span>
                                解析：<span v-html="itemInner.answerAnalysis"></span>
                              </span>
                            </div>
                            <div class="knowledge">
                              <span>
                                知识点：<span>{{ itemInner.knowledgeNamePath }}</span>
                              </span>
                            </div>
                          </div>
                          <!--子题目~填空-->
                          <div class="childrenQuestionItem" v-if="itemInner.questionType == '40'">
                            <div class="questionTitle">
                              <span class="questionType">填空题</span><span>{{ indexInner + 1 }}.</span><span
                                v-html="itemInner.questionName" class="hh"></span>
                            </div>
                            <div class="questionAnswer">
                              <span>
                                参考答案：
                                <p class="questionAnswerS"
                                  v-for="(itemInner2, indexInner2) in itemInner.standardAnswer.split('####')"
                                  :key="indexInner2">{{ itemInner2 }}</p>
                              </span>
                            </div>
                            <div class="questionAnalysis">
                              <span>
                                解析：<span v-html="itemInner.answerAnalysis"></span>
                              </span>
                            </div>
                            <div class="knowledge">
                              <span>
                                知识点：<span>{{ itemInner.knowledgeNamePath }}</span>
                              </span>
                            </div>
                          </div>
                          <!--子题目~判断-->
                          <div class="childrenQuestionItem" v-if="itemInner.questionType == '30'">
                            <div class="questionTitle">
                              <span class="questionType">判断题</span><span>{{ indexInner + 1 }}.</span><span
                                v-html="itemInner.questionName" class="hh"></span>
                            </div>
                            <div class="questionOption">
                              <span v-for="(itemInner2, indexInner2) in JSON.parse(itemInner.optionJson)"
                                :key="indexInner2">
                                {{ itemInner2.key }}.{{ itemInner2.content }}
                                <p v-if="itemInner2.img">
                                  <el-image style="height: 100px;min-height: 100px;" :src="itemInner2.img" :fit="cover"
                                    :preview-src-list="[itemInner2.img]">
                                  </el-image>
                                </p>

                              </span>
                            </div>
                            <div class="questionAnswer">
                              <span>
                                正确答案：{{ itemInner.standardAnswer }}
                              </span>
                            </div>
                            <div class="questionAnalysis">
                              <span>
                                解析：<span v-html="itemInner.answerAnalysis"></span>
                              </span>
                            </div>
                            <div class="knowledge">
                              <span>
                                知识点：<span>{{ itemInner.knowledgeNamePath }}</span>
                              </span>
                            </div>
                          </div>
                          <!--子题目~简答-->
                          <div class="childrenQuestionItem" v-if="itemInner.questionType == '50'">
                            <div class="questionTitle">
                              <span class="questionType">简答题</span><span>{{ indexInner + 1 }}.</span><span
                                v-html="itemInner.questionName" class="hh"></span>
                            </div>
                            <div class="questionAnswer">
                              <span>
                                参考答案：{{ itemInner.standardAnswer }}
                              </span>
                            </div>
                            <div class="questionAnalysis">
                              <span>
                                解析：<span v-html="itemInner.answerAnalysis"></span>
                              </span>
                            </div>
                            <div class="knowledge">
                              <span>
                                知识点：<span>{{ itemInner.knowledgeNamePath }}</span>
                              </span>
                            </div>
                          </div>
                          <!--子题目~计算-->
                          <div class="childrenQuestionItem" v-if="itemInner.questionType == '60'">
                            <div class="questionTitle">
                              <span class="questionType">计算题</span><span>{{ indexInner + 1 }}.</span><span
                                v-html="itemInner.questionName" class="hh"></span>
                            </div>
                            <div class="questionAnswer">
                              <span>
                                参考答案：{{ itemInner.standardAnswer }}
                              </span>
                            </div>
                            <div class="questionAnalysis">
                              <span>
                                解析：<span v-html="itemInner.answerAnalysis"></span>
                              </span>
                            </div>
                            <div class="knowledge">
                              <span>
                                知识点：<span>{{ itemInner.knowledgeNamePath }}</span>
                              </span>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                    <div class="questionInnerRight">
                      <el-button type="warning" size="mini" icon="el-icon-edit"
                        @click="handleCreate(item.questionId)">编辑</el-button>
                      <el-button type="danger" size="mini" icon="el-icon-delete"
                        @click="handleDel(item.questionId)">删除</el-button>
                    </div>
                  </div>
                </div>
                <!--组合题(复合题型)-->
                <div class="questionItem" v-if="item.questionType == '70'">
                  <div class="questionInner">
                    <div class="questionInnerLeft">
                      <div class="questionTitle">
                        <span class="questionType">组合题(复合题型)</span><span>{{ indexMethod(index) }}.</span><span
                          v-html="item.questionName" class="hh"></span>
                      </div>
                      <span class="showChildrenQuestion"
                        @click="showChildrenQuestion(index)">点击{{ item.showChild ? '隐藏' : '查看' }}子题目</span>
                      <!--子题目-->
                      <div class="childrenQuestion" v-if="item.showChild">
                        <div v-for="(itemInner, indexInner) in item.childList" :key="indexInner">
                          <!--子题目~单选-->
                          <div class="childrenQuestionItem" v-if="itemInner.questionType == '10'">
                            <div class="questionTitle">
                              <span class="questionType">单选题</span><span>{{ indexInner + 1 }}.</span><span
                                v-html="itemInner.questionName" class="hh"></span>
                            </div>
                            <div class="questionOption">
                              <span v-for="(itemInner2, indexInner2) in JSON.parse(itemInner.optionJson)"
                                :key="indexInner2">
                                {{ itemInner2.key }}.{{ itemInner2.content }}
                                <p v-if="itemInner2.img">
                                  <el-image style="height: 100px;min-height: 100px;" :src="itemInner2.img" :fit="cover"
                                    :preview-src-list="[itemInner2.img]">
                                  </el-image>
                                </p>

                              </span>
                            </div>
                            <div class="questionAnswer">
                              <span>
                                正确答案：{{ itemInner.standardAnswer }}
                              </span>
                            </div>
                            <div class="questionAnalysis">
                              <span>
                                解析：<span v-html="itemInner.answerAnalysis"></span>
                              </span>
                            </div>
                            <div class="knowledge">
                              <span>
                                知识点：<span>{{ itemInner.knowledgeNamePath }}</span>
                              </span>
                            </div>
                          </div>
                          <!--子题目~多选-->
                          <div class="childrenQuestionItem" v-if="itemInner.questionType == '20'">
                            <div class="questionTitle">
                              <span class="questionType">多选题</span><span>{{ indexInner + 1 }}.</span><span
                                v-html="itemInner.questionName" class="hh"></span>
                            </div>
                            <div class="questionOption">
                              <span v-for="(itemInner2, indexInner2) in JSON.parse(itemInner.optionJson)"
                                :key="indexInner2">
                                {{ itemInner2.key }}.{{ itemInner2.content }}
                                <p v-if="itemInner2.img">
                                  <el-image style="height: 100px;min-height: 100px;" :src="itemInner2.img" :fit="cover"
                                    :preview-src-list="[itemInner2.img]">
                                  </el-image>
                                </p>

                              </span>
                            </div>
                            <div class="questionAnswer">
                              <span>
                                正确答案：{{ itemInner.standardAnswer }}
                              </span>
                            </div>
                            <div class="questionAnalysis">
                              <span>
                                解析：<span v-html="itemInner.answerAnalysis"></span>
                              </span>
                            </div>
                            <div class="knowledge">
                              <span>
                                知识点：<span>{{ itemInner.knowledgeNamePath }}</span>
                              </span>
                            </div>
                          </div>
                          <!--子题目~填空-->
                          <div class="childrenQuestionItem" v-if="itemInner.questionType == '40'">
                            <div class="questionTitle">
                              <span class="questionType">填空题</span><span>{{ indexInner + 1 }}.</span><span
                                v-html="itemInner.questionName" class="hh"></span>
                            </div>
                            <div class="questionAnswer">
                              <span>
                                参考答案：
                                <p class="questionAnswerS"
                                  v-for="(itemInner2, indexInner2) in itemInner.standardAnswer.split('####')"
                                  :key="indexInner2">{{ itemInner2 }}</p>
                              </span>
                            </div>
                            <div class="questionAnalysis">
                              <span>
                                解析：<span v-html="itemInner.answerAnalysis"></span>
                              </span>
                            </div>
                            <div class="knowledge">
                              <span>
                                知识点：<span>{{ itemInner.knowledgeNamePath }}</span>
                              </span>
                            </div>
                          </div>
                          <!--子题目~判断-->
                          <div class="childrenQuestionItem" v-if="itemInner.questionType == '30'">
                            <div class="questionTitle">
                              <span class="questionType">判断题</span><span>{{ indexInner + 1 }}.</span><span
                                v-html="itemInner.questionName" class="hh"></span>
                            </div>
                            <div class="questionOption">
                              <span v-for="(itemInner2, indexInner2) in JSON.parse(itemInner.optionJson)"
                                :key="indexInner2">
                                {{ itemInner2.key }}.{{ itemInner2.content }}
                                <p v-if="itemInner2.img">
                                  <el-image style="height: 100px;min-height: 100px;" :src="itemInner2.img" :fit="cover"
                                    :preview-src-list="[itemInner2.img]">
                                  </el-image>
                                </p>

                              </span>
                            </div>
                            <div class="questionAnswer">
                              <span>
                                正确答案：{{ itemInner.standardAnswer }}
                              </span>
                            </div>
                            <div class="questionAnalysis">
                              <span>
                                解析：<span v-html="itemInner.answerAnalysis"></span>
                              </span>
                            </div>
                            <div class="knowledge">
                              <span>
                                知识点：<span>{{ itemInner.knowledgeNamePath }}</span>
                              </span>
                            </div>
                          </div>
                          <!--子题目~简答-->
                          <div class="childrenQuestionItem" v-if="itemInner.questionType == '50'">
                            <div class="questionTitle">
                              <span class="questionType">简答题</span><span>{{ indexInner + 1 }}.</span><span
                                v-html="itemInner.questionName" class="hh"></span>
                            </div>
                            <div class="questionAnswer">
                              <span>
                                参考答案：{{ itemInner.standardAnswer }}
                              </span>
                            </div>
                            <div class="questionAnalysis">
                              <span>
                                解析：<span v-html="itemInner.answerAnalysis"></span>
                              </span>
                            </div>
                            <div class="knowledge">
                              <span>
                                知识点：<span>{{ itemInner.knowledgeNamePath }}</span>
                              </span>
                            </div>
                          </div>
                          <!--子题目~计算-->
                          <div class="childrenQuestionItem" v-if="itemInner.questionType == '60'">
                            <div class="questionTitle">
                              <span class="questionType">计算题</span><span>{{ indexInner + 1 }}.</span><span
                                v-html="itemInner.questionName" class="hh"></span>
                            </div>
                            <div class="questionAnswer">
                              <span>
                                参考答案：{{ itemInner.standardAnswer }}
                              </span>
                            </div>
                            <div class="questionAnalysis">
                              <span>
                                解析：<span v-html="itemInner.answerAnalysis"></span>
                              </span>
                            </div>
                            <div class="knowledge">
                              <span>
                                知识点：<span>{{ itemInner.knowledgeNamePath }}</span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="questionInnerRight">
                      <el-button type="warning" size="mini" icon="el-icon-edit"
                        @click="handleCreate(item.questionId)">编辑</el-button>
                      <el-button type="danger" size="mini" icon="el-icon-delete"
                        @click="handleDel(item.questionId)">删除</el-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>


        </div>
      </div>
      <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
    </div>
    <!--题库导入-->
    <el-dialog :title="'基础题导入'" :visible.sync="centerDialogVisible" width="50%" center @close="doCloseload"
      class="exportdialog1">
      <div class="flexdcc">
        <!-- <div class="export-box">
          <h1>
            1
            <span>选择导入题目所属知识点</span>
          </h1>
          <el-form ref="uploadData" :model="uploadData" label-width="100px" size="small" :rules="uploadRules" style="width: 100%;margin-top: 20px;">
            <el-form-item label="知识点" prop="knowledgeId" class="form-item">
              <el-cascader
                  v-model="uploadData.knowledgeId"
                  :options="knowledgeIdList"
                  :props="{
                      checkStrictly: true,
                      emitPath: false,
                      label:'knowledgeName',
                      value:'knowledgeId',
                      children:'children'
                    }"
                  filterable
                  placeholder="请选择知识点"
                  style="width: 200px;">
              </el-cascader>
            </el-form-item>
          </el-form>
        </div> -->
        <div class="export-box">
          <h1>
            1
            <span>下载并填写导入模板</span>
          </h1>
          <div class="df">
            <span @click="downloadExcel()" style="
                    margin-top: 20px;
                    margin-right: 20px;
                    color: #409eff;
                    cursor: pointer;
                  ">基础题导入模板.xls</span>
          </div>
        </div>
        <div class="export-box">
          <h1>
            2
            <span>导入编写好的Excel文档</span>
          </h1>

          <el-form :inline="true" ref="DialogForm" :model="DialogForm" :rules="Dialogrules"
            style="align-items: center; display: flex; margin-top: 20px">
          </el-form>
          <div class="flexdc">
            <div class="df" style="margin: 0 0 10px">
              <el-upload class="upload-demo upload-workers" :action="actionUrl" :on-error="handleError"
                :on-success="handleSuccess" :on-change="uploadChange" :show-file-list="false" :auto-upload="false">
                <el-button class="bgc-bv" style="font-size: 12px; width: 80%">浏览</el-button>
              </el-upload>
              <p v-if="fileName" style="margin-top: 10px; margin-left: 10px">
                当前选择文件：
                <span style="color: #f46173">{{ fileName }}</span>
              </p>
              <p v-else style="margin-top: 10px; margin-left: 10px">
                未选择文件
              </p>
            </div>
            <div>
              <el-button class="bgc-bv" size="mini" style="margin-top: 10px; height: 35px"
                @click="doExport('DialogForm')">开始导入</el-button>
            </div>
            <div style="margin-top: 10px" v-if="progressVal > 0">
              <el-progress :text-inside="true" :stroke-width="20" :percentage="progressVal"></el-progress>
            </div>
          </div>
        </div>
        <div class="export-box">
          <h1>
            3
            <span>导入结果</span>
          </h1>
          <div class="df studentNum" style="margin: 1rem 0">
            <span>题目总数量:{{ totalNum }}道;</span>
            <span>成功:{{ correctNum }}道;</span>
            <span>失败:{{ errorNum }}道;</span>
          </div>
          <div>
            <el-button class="bgc-bv" style="margin-top: 15px; height: 35px" size="mini" :disabled="errorNum == '0'"
              @click="doExportError">导出错误数据</el-button>
          </div>
          <div style="margin-top: 15px; color: #dd1d35" v-if="errorNum != 0">
            有导入失败题目，请导出错误数据，将导入失败题目调整后重新上传
          </div>
        </div>
      </div>
    </el-dialog>
     <!--鉴定机构通用导入-->
      <!-- businessType  导入业务类型标识 [ BUSINESS_TYPE ]-->
      <!-- importType  导入类型 学员-->
      <!-- unit  单位 学员-人-->
      <!--fileType  EXCEL  ZIP-->
      <accreditationAgencyImport
        ref="accreditationAgencyImport"
        @eventBus="accreditationAgencyImportBack"
        businessType="EXAM_GROUP_QUESTION_IMPORT"
        importType="题目"
        unit="道"
        fileType="EXCEL"
        filePath="/static/组合题导入模板.xls"
      />
    <!-- 批量上传start -->
    <!--    <questionbankUploadNew ref="questionPaper" @eventBus="paperBack" />-->
  </div>
</template>
<script>
// import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
import accreditationAgencyImport from "@/components/accreditationAgencyImport.vue";
import html2canvas from "html2canvas";
// import questionbankUploadNew from "@/views/inkQuestionBank/QuestionBankManage/popup/questionbankUpload.vue";

export default {
  name: "QuestionBankManage",
  components: {
    // Empty,
    accreditationAgencyImport,
    PageNum,
    // questionbankUploadNew,
  },
  mixins: [List],
  doNotInit: true,
  data() {
    return {
      //从上一个拿到的行数据
      bankId: '',
      bankName: '',


      stu: '',
      questionName: "", //题目名称
      questionType: "",//题目类型
      knowledgeId: "",//知识点
      userShow: "",//学员是否可见



      //题目类型下拉
      QuestionTypeList: [],

      tableData: [],

      // dialogVisible: false,
      // dialogTitle: '',
      // ruleForm: {
      //   categoryId:'',
      //   questionName:''
      // },
      // rules: {
      //   questionName: [
      //     { required: true, message: "请输入题目内容", trigger: "blur" },
      //   ],
      // },
      pageNum: 1,
      pageSize: 10,



      // 导入题库
      centerDialogVisible: false,
      templatelist: '',
      newvalues: [],
      fileKey: "",
      fileName: "",
      progressVal: 0,
      totalNum: 0,
      correctNum: 0,
      errorNum: 0,
      doExportInDisabled: false,
      // 导入 - 数据
      uploadData: {
        knowledgeId: "" // 知识点id
      },
      // 知识点下拉
      knowledgeIdList: [],
      uploadRules: {
        knowledgeId: [{ required: true, message: '请选择知识点', trigger: 'change' }],
      }
    };
  },
  computed: {},
  created() {
    this.bankId = this.$route.query.bankId
    this.bankName = this.$route.query.bankName

    // sessionStorage.setItem('pageNumStr',this.pageNum)
    // sessionStorage.setItem('pageSizeStr',this.pageSize)
    // sessionStorage.setItem('questionNameStr',this.questionName)
    // sessionStorage.setItem('questionTypeStr',this.questionType)

    if (sessionStorage.getItem('pageNumStr')) {
      this.pageNum = sessionStorage.getItem('pageNumStr') || 1
      sessionStorage.removeItem('pageNumStr')
    }
    if (sessionStorage.getItem('pageSizeStr')) {
      this.pageSize = sessionStorage.getItem('pageSizeStr') || 10
      sessionStorage.removeItem('pageSizeStr')
    }
    if (sessionStorage.getItem('questionNameStr')) {
      this.questionName = sessionStorage.getItem('questionNameStr')
      sessionStorage.removeItem('questionNameStr')
    }
    if (sessionStorage.getItem('questionTypeStr')) {
      this.questionType = sessionStorage.getItem('questionTypeStr')
      sessionStorage.removeItem('questionTypeStr')
    }

    this.getKnowledgeList()

    this.getQuestionTypeList();

    this.getData(this.pageNum)

  },
  methods: {
    handleExportToUnite(){
      this.$refs.accreditationAgencyImport.showPopup(this.bankId)
    },
    accreditationAgencyImportBack(){
      this.getData(this.pageNum)
    },
    //获取知识点下拉数据
    getKnowledgeList() {
      this.$post("/biz/exam/bank/knowledge/selectable/tree", { "bankId": this.bankId }, 3000, true, 6)
        .then((ret) => {
          if (ret.status == 0) {
            this.knowledgeIdList = this.delNullData(ret.data)
          }
        })
        .catch((err) => {
          return;
        });
    },
    // 去除空数据
    delNullData(list) {
      list.forEach((e, i) => {
        if (e.children.length == 0) {
          e.children = undefined
        } else {
          this.delNullData(e.children)
        }
      })
      return list
    },
    // /* 批量上传start */
    // BatchUpload() {
    //   this.$refs.questionPaper.showPopUp(this.bankId);
    // },
    // paperBack() {
    //   this.getData();
    //   this.getNum()
    // },
    // 点击批量导入
    handleExport() {
      this.centerDialogVisible = true;

    },
    downloadExcel() {
      // /**
      //  * 下载模板
      //  * @param param 参数
      //  */
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = "/static/基础题导入模板.xls";
      link.setAttribute("download", "基础题导入模板.xls");
      document.body.appendChild(link);
      link.click();
    },
    // 导入学员
    uploadChange(file) {
      this.fileName = file.name;
      let size = file.size / 1024 / 1024;
      // let fileName = file.name.split(".");
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isXLSX = extension === "XLSX";
      const isxlsx = extension === "xlsx";
      const isXLS = extension === "XLS";
      const isxls = extension === "xls";
      if (!isXLSX && !isxlsx && !isXLS && !isxls) {
        this.$message.error("只能上传后缀是.xlsx或者.xls的文件");
        return;
      }
      if (size > 3) {
        this.$message.error("文件大小不能超过3M");
        return;
      }
      let formData = new FormData();
      formData.append("folder ", "TEMP");
      formData.append("file ", file.raw);
      formData.append("fileType ", extension);

      this.$Postformat("/sys/upload", formData)
        .then((result) => {
          // console.log(result);
          this.fileKey = result.data.fileKey;
          // this.fileName = result.data.fileKey;
        })
        .catch((err) => {
          return;
        });
    },
    doExport() {
      if (this.fileKey == "") {
        this.$message({
          message: "请选择文件",
          type: "warning",
        });
      } else {
        this.doExportInDisabled = true;
        if (this.fileKey) {
          const parmar = {
            bankId: this.bankId,
            excelUrl: this.fileKey,
            // knowledgeId: this.uploadData.knowledgeId
          };
          this.$post("/biz/exam/bank/question/importPaper", parmar, 5000, true, 6)
            .then((res) => {
              if (res.status == 0) {
                if (res.status == 0) {
                  // console.log(res.data);
                  this.doProgress(res.data, this.bankId);
                }
              }
            })
            .catch(() => {
              return;
            });
        } else {
          this.$message({
            message: "请选择文件",
            type: "warning",
          });
        }
      }
    },
    //获取导入进度
    /* /biz/projectUser/importProgress */
    doProgress(batchId, bankId) {
      this.$post(
        "/biz/exam/bank/question/importPaper/progress",
        {
          batchId,
          bankId: bankId,
        }, 5000, true, 6
      ).then((ret) => {
        if (!ret.data.status) {
          this.progressVal = ret.data.progress;
          setTimeout(() => {
            this.doProgress(batchId, projectId);
          }, 2000);
        } else {
          if (ret.data.message) {
            this.$message({
              type: "error",
              message: ret.data.message,
            });
          } else {
            this.totalNum = ret.data.totalNum;
            this.errorNum = ret.data.errorNum;
            this.correctNum = ret.data.correctNum;
            this.importType = ret.data.importType;
            this.batchId = ret.data.batchId;
            this.progressVal = ret.data.progress;
            this.$message({
              type: "success",
              message: "导入成功",
            });
          }
        }
      });
    },
    //导出错误数据
    doExportError() {
      // console.log(this.batchId);
      if (this.batchId == "" || this.fileKey == "") {
        this.$message({
          message: "还没有导入数据，请先导入文档",
          type: "warning",
        });
      } else {
        this.$post(
          "/biz/exam/bank/question/importPaper/exportErrorInfo",
          {
            batchId: this.batchId,
            bankId: this.bankId,
          },
          5000, true, 6
        ).then((res) => {
          if (res.status == 0) {
            window.open(res.data);
          }
        });
      }
    },
    // 关闭导入学员弹窗
    doCloseload() {
      this.centerDialogVisible = false;
      this.newvalues = [];
      this.fileKey = "";
      this.fileName = "";
      this.progressVal = 0
      this.totalNum = 0;
      this.correctNum = 0;
      this.errorNum = 0;
      this.doExportInDisabled = false;

      // this.uploadData.knowledgeId = "";
      this.getData();
    },
    // 去题库管理页
    toQuestionBankManage() {
      this.$router.push({
        path: "/inkQuestionBank/QuestionBankManage/QuestionBankManage",
        query: {
          refresh: true
        }
      });
    },
    indexMethod(index) {
      return (this.pageNum - 1) * this.pageSize + index + 1;
    },
    // 点击查看子题目
    // showChildrenQuestion(){
    //   this.showChildrenQuestionFlag = !this.showChildrenQuestionFlag
    // },
    // 展示子题目
    showChildrenQuestion(index) {
      this.tableData.forEach((e, i) => {
        if (i == index) {
          if (!e.showChild) {
            this.$set(this.tableData[i], 'showChild', true)
          } else {
            this.$set(this.tableData[i], 'showChild', false)
          }
        }
        // else{
        //   this.$set(this.tableData[i],'showChild',false)
        // }
      })
    },
    //获取测评分类标识--码值
    getQuestionTypeList() {
      const QuestionType = this.$setDictionary("TOOL_QUESTION_TYPE", "list");
      for (const key in QuestionType) {
        this.QuestionTypeList.push({
          value: key,
          label: QuestionType[key],
        });
      }
    },
    getData(pageNum = 1) {
      let _this = this
      const params = {
        bankId: this.$route.query.bankId,
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      // this.pageNum = pageNum
      if (this.questionName) {
        params.questionName = this.questionName
      }
      if (this.questionType) {
        params.questionType = this.questionType
      }
      if (this.knowledgeId) {
        params.knowledgeId = this.knowledgeId
      }
      if (this.userShow == false || this.userShow == true) {
        params.userShow = this.userShow
      }

      // console.log(params)
      this.doFetch(
        {
          url: "/biz/exam/bank/question/pageList",
          params,
          pageNum,
        }, true, 6
      );
    },
    // 新增编辑
    handleCreate(questionId) {
      // stu 1题库  2组合题(单一题型)/组合题(复合题型)  3试卷
      let stu = '1'
      sessionStorage.setItem('pageNumStr', this.pageNum)
      sessionStorage.setItem('pageSizeStr', this.pageSize)
      sessionStorage.setItem('questionNameStr', this.questionName)
      sessionStorage.setItem('questionTypeStr', this.questionType)
      this.$router.push({
        path: '/inkQuestionBank/QuestionBankManage/QuestionAddOrEdit',
        query: {
          stu: stu,
          bankId: this.bankId,
          bankName: this.bankName,
          questionId: questionId,

          // pageNum: this.pageNum,
          // pageSize: this.pageSize,
          //
          // questionNameStr: this.questionName,
          // questionTypeStr: this.questionType,
        }
      })
    },
    // 删除题目
    handleDel(questionId) {
      this.$confirm("确认删除该题目吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$post("/biz/exam/bank/question/delete", {
          questionId
        }, 3000, true, 6).then(() => {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
          this.getData(-1)
        });
      });
    }

  },

  beforeRouteLeave: resetKeepAlive,

};
</script>
<style lang="less">
.questionInner {

  //答题题目有表格的样式重置一下
  table {
    min-height: 25px;
    line-height: 25px;
    text-align: center;
    border-collapse: collapse;
    font-size: 12px;
  }

  table,
  table tr th,
  table tr td {
    border: 1px solid #a8aeb2;
    padding: 2px 4px;
  }
}
</style>
<style lang="less" scoped>
.addlist {
  .el-icon-plus:before {
    content: "\e6d9";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.table-switch {
  display: flex;
  align-items: center;
  justify-content: center;

  .el-switch__core {
    width: 40px !important;
  }

  .el-switch__core:after {
    background-color: #fff;
  }

  span {
    line-height: 20px;
    margin-left: 5px;
  }
}

.operationControl>div>div>span {
  min-width: 6rem;
}

.itemLabel {
  display: inline-block;
  width: 3rem;
}

.operationControl {
  position: relative;
}


//题目样式
.questionBox>div {
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }
}

//.questionItem{
//  margin-bottom: 15px;
//  &:last-child{
//    margin-bottom: 0;
//  }
//}
.questionInner {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #2878FF;
  transition: 0.3s;

  &:hover {
    background: #ecf5ff;
  }
}

.questionInnerLeft {
  position: relative;
  width: 100%;
}

.questionTitle {
  line-height: 1.5;
  font-size: 16px;
  color: #333;
  margin-bottom: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.questionType {
  white-space: nowrap;
  border-radius: 4px;
  margin-right: 6px;
  padding: 2px 8px;
  font-size: 14px;
  color: #2878ff;
  background: #fff;
  border: 1px solid #2878ff;
}

.questionOption {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  line-height: 1.5;
  font-size: 14px;
  color: #333;
  margin-bottom: 6px;

  span {
    width: 25%;
    padding-right: 8px;
    margin-bottom: 10px;

  }
}

.questionAnswer {
  line-height: 1.5;
  font-size: 14px;
  color: #333;
  margin-bottom: 16px;
  width: 100%;

  &>span {
    width: 100%;
    border-radius: 4px;
    padding: 4px 8px;
    color: #67c23a;
    background: #f0f9eb;
    border-color: #c2e7b0;
  }

  .questionAnswerS {
    margin-top: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid rgba(133, 206, 97, 0.3);

    &:last-child {
      border: 0;
    }
  }
}

.questionAnalysis {
  line-height: 1.5;
  font-size: 14px;
  color: #333;
  margin-bottom: 16px;
  width: 100%;

  &>span {
    width: 100%;
    border-radius: 4px;
    padding: 4px 8px;
    color: #e6a23c;
    background: #fdf6ec;
    border-color: #f5dab1;
  }
}

.knowledge {
  line-height: 1.5;
  font-size: 14px;
  color: #333;
  width: 100%;

  &>span {
    width: 100%;
    border-radius: 4px;
    padding: 4px 8px;
    color: #67c23a;
    background: #f0f9eb;
    border-color: #c2e7b0;
  }

  .questionAnswerS {
    margin-top: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid rgba(133, 206, 97, 0.3);

    &:last-child {
      border: 0;
    }
  }
}

.questionInnerRight {
  min-width: 156px;
  margin-left: 15px;
}

.showChildrenQuestion {
  cursor: pointer;
  color: #2878ff;
}

.childrenQuestionItem {
  position: relative;
  margin-top: 15px;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #2878FF;
  margin-right: -169px;
  //.questionTitle,.questionAnswer,.questionAnalysis,.questionOption{
  //  width: calc(100% - 149px);
  //}
  //.mt16{
  //  position: absolute;
  //  width: 149px;
  //  top: 0;
  //  right: 0;
  //}
  //&:last-child{
  //  margin-bottom: 0;
  //}
}

//导入题库
.exportdialog1 {
  .el-dialog__title {
    font-size: 16px;
  }

  .export-box {
    width: 100%;
    margin-bottom: 20px;

    h1 {
      padding: 0 5px 10px;
      display: flex;
      align-items: flex-end;
      font-size: 40px;
      border-bottom: 1px dashed #ccc;

      span {
        margin-left: 20px;
        font-size: 14px;
      }
    }

    >div {
      padding-left: 30px;
    }

    .el-button.is-disabled,
    .el-button.is-disabled:focus,
    .el-button.is-disabled:hover {
      color: #fff;
      background: #c3c3c3;
      border: none;
    }
  }

  /deep/.el-upload {
    width: 80px;
    height: 35px;
    border: 0;

    .el-button {
      width: 80px !important;
      height: 33px;
      line-height: 33px;
      padding: 0 20px;
    }
  }
}

.docsDialog {
  .el-form-item {
    margin-bottom: 0.5rem;
  }
}

.studentNum {
  margin: 20px 0 10px;

  span {
    margin: 0 10px;
  }
}

//强制换行
.hh {
  word-break: break-all;
  word-wrap: break-word;
  //white-space: pre-wrap;
}
</style>